import Storage from '../../utils/Storage';

export default {
  state() {
    return {
      sidebarIsVisible: false,
      sidebarUserId: Storage.getItem('info')?.user?.id || null,
      isPersonalSidebar: true,
    };
  },
  mutations: {
    setVisible(state, payload) {
      state.sidebarIsVisible = payload.visible;
    },
    setSidebarUserId(state, payload) {
      state.sidebarUserId = payload.userId;
    },
    setIsPersonalSidebar(state, payload) {
      state.isPersonalSidebar = payload.isPersonalSidebar;
    },
  },
  actions: {
    setVisible(context) {
      context.commit('setVisible');
    },
    setSidebarUserId(context) {
      context.commit('setSidebarUserId');
    },
    setIsPersonalSidebar(context) {
      context.commit('setIsPersonalSidebar');
    },
  },
  getters: {
    getSidebarIsVisible(state) {
      return state.sidebarIsVisible;
    },
    getUserId(state) {
      return state.sidebarUserId;
    },
    getIsPersonalSidebar(state) {
      return state.isPersonalSidebar;
    },
  },
};
