<template>
  <div id="app-sidebar-9" class="left-sidebar">
    <div class="flex flex-column h-full background-main">
      <div class="flex align-items-center justify-content-center flex-shrink-0" style="height: 40px">
        <router-link :to="{ name: 'UserProfileMe', params: { id: $store.getters['auth/user'].id } }" class="main-logo">
          <span class="text-light">vionvi</span>
        </router-link>
      </div>
      <Link
        v-for="item of items"
        :key="item"
        :to="item.to && item.to.name == 'Contract' ? $store.getters.getContractViewSelection : item.to"
        @click="onClick(item)"
        :class="[
          ' flex lg:flex-column border-left align-items-center text-center cursor-pointer p-3 lg:justify-content-center hover:border-300 transition-duration-150 transition-colors p-ripple',
        ]"
      >
        <!-- Небольшой костылек для уведомлений -->
        <template v-if="item.to && item.to.name == 'WazzupGlobal' && $store.getters.countWazzup > 0">
          <i
            :class="item.icon + ' mr-2 lg:mr-0 lg:text-2xl'"
            style="font-size: 1.3em !important; color: #bbc3d6"
            v-badge.danger="$store.getters.countWazzup"
          ></i>
        </template>
        <template v-else-if="item.to && item.to.name == 'Task' && overdueTaskCount > 0">
          <i
            :class="item.icon + ' mr-2 lg:mr-0 lg:text-2xl'"
            style="font-size: 1.3em !important; color: #bbc3d6"
            v-badge.danger="overdueTaskCount"
          ></i>
        </template>
        <template v-else-if="item.to && item.to.name == 'Lead' && newLeadCount > 0">
          <i
            :class="item.icon + ' mr-2 lg:mr-0 lg:text-2xl'"
            style="font-size: 1.3em !important; color: #bbc3d6"
            v-badge.warning="newLeadCount"
          ></i>
        </template>
        <template v-else-if="item.to && item.to.name == 'Pay' && overduePayCount > 0">
          <i
            :class="item.icon + ' mr-2 lg:mr-0 lg:text-2xl'"
            style="font-size: 1.3em !important; color: #bbc3d6"
            v-badge.danger="overduePayCount"
          ></i>
        </template>
        <template v-else>
          <i :class="item.icon + ' mr-2 lg:mr-0 lg:text-2xl'" style="font-size: 1.3em !important; color: #bbc3d6"></i>
        </template>
        <span class="font-medium inline lg:block mt-2" style="color: #bbc3d6; font-size: 11px">{{ item.name }}</span>
        <span class="p-ink"></span>
      </Link>

      <Sidebar
        :visible="sidebarVisible"
        @update:visible="$emit('update:sidebarVisible', $event)"
        :modal="sidebarModal"
        @show="onSidebarShow"
        @hide="onSidebarHide"
      >
        <PanelMenu :model="sidebarItems" id="leftSideBarPanelMenu" />
      </Sidebar>
    </div>
  </div>
</template>

<script>
import PanelMenu from 'primevue/panelmenu';
import Sidebar from 'primevue/sidebar';
import Link from './ui/Link.vue';
import AppWazzupService from '@/modules/app/services/AppWazzupService';
import TaskService from '@/modules/task/services/TaskService';
import LeadService from '@/modules/lead/services/LeadService';
import PayService from '@/modules/pay/services/PayService';

export default {
  components: {
    PanelMenu,
    Sidebar,
    Link,
  },
  props: {
    sidebarVisible: Boolean,
    sidebarModal: Boolean,
  },
  emits: ['update:sidebarVisible', 'update:sidebarModal'],
  data() {
    return {
      sidebarItems: [],
      items: [],
      appWazzupService: new AppWazzupService(),
      taskService: new TaskService(),
      leadService: new LeadService(),
      payService: new PayService(),
      overdueTaskCount: 0,
      newLeadCount: 0,
      overduePayCount: 0,
    };
  },
  computed: {
    struc() {
      return this.$store.getters['auth/struc'];
    },
  },
  watch: {
    struc() {
      this.load();
    },
  },
  async created() {
    this.load();

    if (this.$store.getters['auth/app'].length > 0) {
      for (let app of this.$store.getters['auth/app']) {
        if (app.identifier == 'wazzup') {
          this.appWazzupService.socket(this.$store.getters['auth/user'].id);
        }
      }
    }
    this.overdueTaskCount = await this.taskService.overdue();
    this.newLeadCount = await this.leadService.new();
    this.overduePayCount = await this.payService.overdue();
  },
  methods: {
    load() {
      this.items = this.struc;

      this.checkRoute(this.items);

      this.sidebarItems = this.items;
    },
    onClick(item) {
      if (item.items && item.items.length) {
        this.sidebarItems = item.items;
        this.$emit('update:sidebarModal', false);
        this.$emit('update:sidebarVisible', !this.sidebarVisible);
      } else {
        this.$emit('update:sidebarVisible', false);
      }
    },
    onSidebarShow() {
      if (this.sidebarItems === this.items) {
        for (let sidebarItem of this.sidebarItems) {
          sidebarItem.callback = () => {
            this.$emit('update:sidebarVisible', false);
          };
        }
      }
    },
    onSidebarHide() {
      this.$emit('update:sidebarVisible', false);
      this.$emit('update:sidebarModal', true);
      this.sidebarItems = this.items;
    },
    checkRoute(items) {
      // Проверяем действительно ли есть все роуты указанные по имени
      // Т.к. если не проверять и его не будет, то выдает ошибку
      // А проверять нужно, т.к. items кэшируются и бывает не совпадают с реальными
      let routes = this.$router.getRoutes();
      for (let i = items.length; i--; ) {
        if (items[i].visible === false || (!items[i].to && !items[i].items)) {
          // удаляем элемент, который скрыт или это пустая группа
          delete items.splice(i, 1);
          continue;
        }

        let item = items[i];
        if (item.items) {
          this.checkRoute(item.items);
        } else {
          if (item.to) {
            let isDelete = true;
            for (let route of routes) {
              if (item.to.name === route.name) {
                isDelete = false;
                break;
              }
            }

            if (isDelete) {
              // удаляем роут которого нет
              delete items.splice(i, 1);
              continue;
            }

            // В новой версии PanelMenu принимает command вместо to
            item.command = () => {
              this.$router.push(item.to);
              item.callback ? item.callback() : null;
            };
          }
        }

        items[i].label = items[i].name;
      }
    },
  },
};
</script>
