import Service from '@/services/Service';
import http from '@/utils/http-common';
import PayPositionService from './PayPositionService';

class PayService extends Service {

    basePath = 'pay';

    newObject() {
        return new PayService();
    }

    /**
     * Get sum
     * 
     * @returns {int}
     */
    async sum() {
        return await http.get('/' + this.basePath + '/sum', { params: this.prepareRequest() }).then(response => {
            if (response.data.data) {
                return response.data.data.sum;
            }
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Get sum
     * 
     * @returns {array}
     */
    async sumComplex() {
        return await http.get('/' + this.basePath + '/sum-complex', { params: this.prepareRequest() }).then(response => {
            if (response.data.data) {
                return response.data.data;
            }
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Get count
     * 
     * @returns int
     */
    async count() {
        return await http.get('/' + this.basePath + '/count', { params: this.prepareRequest() }).then(response => {
            if (response.data.data) {
                return response.data.data.count;
            }
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Get vat list items
     * @returns {array}
     */
    async vatList() {
        return await http.get('/' + this.basePath + '/vat-list', { params: this.prepareGetRequest() }).then(response => {
            this.errors = {};

            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    /**
     * Get invoice url
     * @returns {array}
     */
    async invoiceUrl(id) {
        id = id || this.id;

        return await http.get('/' + this.basePath + '/invoice-url/' + id).then(response => {
            this.errors = {};

            return response.data.data;
        }).catch(e => this.catchResponse(e));
    }

    async overdue() {
        return await http.get('/' + this.basePath + '/overdue', { params: this.prepareRequest() }).then(response => {
            return response.data;
        }).catch(e => this.catchResponse(e));
    }

    /** @inheritdoc */
    setAttributes(attributes) {
        super.setAttributes(attributes);

        if (this.positions) {
            this.positions = (new PayPositionService()).collection(this.positions);
        }
    }

    async copy(id) {
        let forCopy = this.newObjectAttr();
        await forCopy.show(id);
        this.cost = forCopy.cost;
        this.contractId = forCopy.contractId;
        this.tariffId = forCopy.tariffId;
        this.comment = forCopy.comment;
        this.payItemId = forCopy.payItemId;
        this.orgAccountId = forCopy.orgAccountId;
        this.locationUnitId = forCopy.locationUnitId;
        this.orgExecutorId = forCopy.orgExecutorId;
        this.baseInvoice = forCopy.baseInvoice;
        this.purpose = forCopy.purpose;
        this.commentInvoice = forCopy.commentInvoice;
        this.positions = forCopy.positions;
    }

}

export default PayService;