export default {
  state() {
    return {
      count: 0,
    };
  },
  mutations: {
    setCountWazzup(state, payload) {
      state.count = payload;
    },
  },
  getters: {
    countWazzup(state) {
      return state.count;
    },
  },
};
