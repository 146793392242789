import Storage from '../../utils/Storage';

export default {
  state() {
    return {
      viewSelection: Storage.getItem('contractViewSelection') || { name: 'ContractKanban' },
      funnelId: Storage.getItem('contractFunnelId') || null,
    };
  },
  mutations: {
    setContractViewSelection(state, payload) {
      state.viewSelection = payload;
      Storage.setItem('contractViewSelection', payload);
    },
    setContractFunnelId(state, payload) {
      state.funnelId = payload;
      Storage.setItem('contractFunnelId', payload);
    },
  },
  getters: {
    getContractViewSelection(state) {
      return state.viewSelection;
    },
    getContractFunnelId(state) {
      return state.funnelId;
    },
  },
};
