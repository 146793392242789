<template>
  <div
    class="flex justify-content-between align-items-center ps-3 pe-3 surface-section relative lg:static border-bottom-1 surface-border custom-nav"
    style="min-height: 60px; box-shadow: 0px 2px 10px 0px #d4e0ee !important"
  >
    <div class="flex">
      <a
        class="custom-nav-bars cursor-pointer my-auto block lg:hidden text-700 mr-3"
        @click="$emit('update:sidebarVisible', !sidebarVisible)"
      >
        <i class="fa-solid fa-bars"></i>
      </a>
      <div class="d-flex">
        <!-- <i class="fa-solid fa-magnifying-glass"></i> -->
        <!-- <input
          class="p-inputtext p-component border-none w-10rem sm:w-20rem border-none w-10rem sm:w-20rem"
          type="text"
          placeholder="Search"
        /> -->
        <Button
          v-if="isMobile"
          icon="pi pi-search"
          class="p-button-secondary p-button-outlined me-1"
          @click="toggleSearchBar"
        />
        <SearchView
          v-if="
            (!isMobile || searchBarVisible) &&
            ($store.getters.can('lead.index') ||
              $store.getters.can('contract.index') ||
              $store.getters.can('client.index'))
          "
          :service="searchService"
        />
      </div>
      <div
        v-if="
          !searchBarVisible &&
          ($store.getters.can('lead.store') ||
            $store.getters.can('contract.store') ||
            $store.getters.can('task.store') ||
            $store.getters.can('user.store') ||
            $store.getters.can('client.store'))
        "
        class="flex"
      >
        <Button
          icon="fa-solid fa-plus"
          class="mx-2 p-button p-component p-button-icon-only p-button-secondary p-button-outlined"
          id="dropdownInfoTobBar"
          data-bs-toggle="dropdown"
          v-tooltip.bottom="$t('Create')"
          aria-expanded="false"
        />
        <ul class="dropdown-menu" aria-labelledby="dropdownInfoTobBar">
          <li v-if="this.$store.getters.can('lead.store')">
            <router-link :to="{ name: 'LeadCreate' }" class="dropdown-item">
              <div class="d-flex align-items-center">
                <div>{{ $t('Create a lead') }}</div>
              </div>
            </router-link>
          </li>
          <li v-if="this.$store.getters.can('contract.store')">
            <router-link :to="{ name: 'ContractCreate' }" class="dropdown-item">
              <div class="d-flex align-items-center">
                <div>{{ $t('Create a deal') }}</div>
              </div>
            </router-link>
          </li>
          <li v-if="this.$store.getters.can('task.store')">
            <router-link :to="{ name: 'TaskCreate' }" class="dropdown-item">
              <div class="d-flex align-items-center">
                <div>{{ $t('Create a task') }}</div>
              </div>
            </router-link>
          </li>
          <li v-if="this.$store.getters.can('client.store')">
            <router-link :to="{ name: 'ClientCreate' }" class="dropdown-item">
              <div class="d-flex align-items-center">
                <div>{{ $t('Create a client') }}</div>
              </div>
            </router-link>
          </li>
          <li v-if="this.$store.getters.can('user.store')">
            <router-link :to="{ name: 'UserCreate' }" class="dropdown-item">
              <div class="d-flex align-items-center">
                <div>{{ $t('Create a user') }}</div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- <a class="cursor-pointer block lg:hidden text-700 p-ripple">
      <i class="fa-solid fa-ellipsis-v fs-5"></i>
      <span class="p-ink"></span>
    </a> -->
    <ul v-if="!searchBarVisible" class="list-none p-0 m-0 d-flex align-items-center select-none flex-row right-0 z-3">
      <!-- <i class="fa-regular fa-comments" v-badge.danger style="color: #6f7985"></i> -->
      <li
        class="mx-2"
        @click="onShowChatMessage()"
        v-if="$store.getters.getChatMessageUnread && $store.getters.getChatMessageUnread.totalCount > 0"
      >
        <i
          class="bi bi-chat-text cursor-pointer"
          v-badge.danger="$store.getters.getChatMessageUnread.totalCount"
          style="font-size: 1.2rem; color: #6f7985"
          v-tooltip.bottom="$t('Chats')"
        ></i>
      </li>
      <li class="mx-2" @click="onShowChatMessage()" v-else>
        <i
          class="bi bi-chat-text cursor-pointer"
          style="font-size: 1.2rem; color: #6f7985"
          v-tooltip.bottom="$t('Chats')"
        ></i>
      </li>
      <li class="mx-2">
        <NotificationBellView />
      </li>
      <li class="mx-2" @click="() => $router.push({ name: 'PayInOutCreateOne', params: { tariffIdentifier: 'base' } })">
        <i
          class="bi bi-wallet cursor-pointer"
          style="font-size: 1.2rem; color: #6f7985"
          v-tooltip.bottom="$t('Payment')"
        ></i>
      </li>
      <li class="ml-2 mr-4">
        <SupportBar />
      </li>
      <li>
        <AvatarView
          :name="$store.getters['auth/user'].name"
          :fileAvatar="userService.fileAvatar"
          id="dropdownMenuTobBar"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          class="flex cursor-pointer"
        />
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuTobBar">
          <li class="px-2">
            <LangSelect />
          </li>
          <li>
            <router-link
              :to="{ name: 'UserProfileMe', params: { id: $store.getters['auth/user'].id } }"
              class="dropdown-item"
            >
              <div class="d-flex align-items-center">
                <div class="bi bi-person" style="width: 25px; color: #6f7985"></div>
                <div>{{ $t('Profile') }}</div>
              </div>
            </router-link>
          </li>
          <li>
            <div
              class="dropdown-item d-flex align-items-center cursor-pointer"
              href="#"
              @click="$store.dispatch('auth/logout')"
            >
              <div class="bi bi-box-arrow-right" style="width: 25px; color: #6f7985"></div>
              <div>{{ $t('Log out') }}</div>
            </div>
          </li>
        </ul>
        <span class="p-ink"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import { initSocketServer } from '../services/Socket.js';
import InputNumber from 'primevue/inputnumber';
import FormField from './ui/form/FormField.vue';
import Button from 'primevue/button';
import Menubar from 'primevue/menubar';
import NotificationBellView from '../modules/notification/components/NotificationBellView.vue';
import AvatarView from './ui/AvatarView.vue';
import SearchView from './ui/SearchView.vue';
import SupportBar from './SupportBar.vue';
import LangSelect from '@/modules/lang/components/LangSelect.vue';
import UserService from '../modules/user/services/UserService';
import SearchService from '../modules/search/services/SearchService';
import ChatMessageService from '../modules/chat/services/ChatMessageService';

export default {
  components: {
    InputNumber,
    FormField,
    Button,
    Menubar,
    NotificationBellView,
    AvatarView,
    SearchView,
    SupportBar,
    LangSelect,
  },
  props: {
    sidebarVisible: Boolean,
  },
  data() {
    return {
      userService: new UserService({
        expand: ['fileAvatar'],
      }),
      items: [
        {
          label: 'File',
          icon: 'fa-regular fa-file',
          items: [
            {
              label: 'New',
              icon: 'fa-solid fa-plus',
              items: [
                {
                  label: 'Bookmark',
                  icon: 'pi pi-fw pi-bookmark',
                },
                {
                  label: 'Video',
                  icon: 'pi pi-fw pi-video',
                },
              ],
            },
            {
              label: 'Delete',
              icon: 'pi pi-fw pi-trash',
            },
            {
              separator: true,
            },
            {
              label: 'Export',
              icon: 'pi pi-fw pi-external-link',
            },
          ],
        },
      ],
      searchService: new SearchService(),
      chatMessageService: new ChatMessageService(),
      isMobile: false,
      searchBarVisible: false,
    };
  },
  mounted() {
    // Подписаться на событие изменения ширины окна
    window.addEventListener('resize', this.checkWindowSize);
    this.checkWindowSize();
  },
  beforeDestroy() {
    // Удалить обработчик события при удалении компонента
    window.removeEventListener('resize', this.checkWindowSize);
  },
  async created() {
    await this.userService.showSearch(this.$store.getters['auth/user'].id);
    initSocketServer('chatMessage', 'public.chatMessage', true, this.$store, 'setChatMessageNotification', this, true);
    initSocketServer(
      'chatMessageUnreadCountEvent',
      'public.chatMessageUnreadCountEvent',
      false,
      this.$store,
      'setChatMessageUnread',
      this
    );
    this.$store.commit('setChatMessageUnread', await this.chatMessageService.unreadMessageCount());
  },
  methods: {
    onShowChatMessage() {
      this.$router.push({
        name: 'ChatMessage',
      });
    },
    toggleSearchBar() {
      this.searchBarVisible = !this.searchBarVisible;
    },
    checkWindowSize() {
      // Определить ширину окна и установить значение isMobile
      this.isMobile = window.innerWidth <= 768;
    },
  },
};
</script>
